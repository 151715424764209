import React from "react";
import naverLoginIcon from "../../res/drawable/naver_login_icon.png";

declare global {
  interface Window {
    naver_id_login: any;
  }
}

class NaverLogin extends React.Component {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js";
    script.charset = "utf-8";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      const naver_id_login = new window.naver_id_login(
        process.env.NEXT_PUBLIC_NAVER_KEY,
        process.env.NEXT_PUBLIC_HOST_URL + "/login/callback"
        // "http://anipick.kirakiratomo.com:8080/login/naver"
      );
      const state = naver_id_login.getUniqState();

      naver_id_login.setButton("green", 4, 55);
      naver_id_login.setDomain(".kirakira-tomo.com");
      naver_id_login.setState(state);
      // naver_id_login.
      // naver_id_login.setPopup();
      naver_id_login.init_naver_id_login();
    };
  }

  render() {
    return <div id="naver_id_login" />;
  }
}

export default NaverLogin;
