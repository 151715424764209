import { isLoginUser } from "@/Utils/Utils";
import NaverLogin from "@/component/molecules/NaverLogin";
import { get } from "@/redux/module/api";
import { Button, TextField } from "@mui/material";
import React, { Component } from "react";
import logo from "@/res/drawable/logo/kirakiralogo.png";
import { orangePrimary } from "@/styles/theme";
import { ca } from "date-fns/locale";

class login extends Component {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = async () => {
    try {
      const res = await isLoginUser();

      console.log(res);

      if (res === false) {
        // alert("로그인이 필요합니다.");
      } else {
        location.href = "/";
      }
    } catch (e: any) {
      // alert("로그인이 필요합니다.");
    }
  };
  render() {
    return (
      <div
        className="centerContainer"
        style={{ height: "100%", fontSize: "0.9rem" }}
      >
        <div style={{ paddingTop: "12vh" }}>
          {/* <img
            src="https://twinkleimg.s3.ap-northeast-2.amazonaws.com/kirakira/test.png"
            alt=""
            style={{ width: "73vw", height: "auto" }}
          /> */}
          <img
            src={logo.src}
            alt=""
            style={{ width: "45vw", height: "auto" }}
          />
        </div>
        <div
          style={{
            marginTop: "3vh",
          }}
        >
          <b>회원 로그인 후 이용하실 수 있습니다.</b>
        </div>
        {/* <br />
        <div>
          <b>어른이의 원더 랜드, 키라키라토모</b> */}
        <br />
        <div>
          <b>키라키라토모와 함께, 편리한 컬렉팅을 시작하세요!</b>
        </div>
        <p></p>
        <p></p>
        <NaverLogin />

        <div
          style={{
            display: "flex",
            paddingTop: "20px",
            margin: "10px",
            justifyContent: "center",
          }}
        >
          <TextField
            className="editForm"
            style={{
              width: "30vw",
            }}
            id="password"
            name="password"
            defaultValue={""}
          />
          <Button
            className="commonOutlinedBtn"
            sx={{
              backgroundColor: "#ffffff",
              borderColor: "#c5c5c5 !important",
              border: 2,
            }}
            color="primary"
            onClick={async () => {
              try {
                const pwd = document.getElementById(
                  "password"
                ) as HTMLInputElement;
                const res = await get("login/test?password=" + pwd.value, {});

                if (res.status === 200) {
                  location.href = "/";
                }
              } catch (e: any) {
                alert("관리자용 메뉴입니다.");
                return;
              }
            }}
            style={{ width: "20vw", height: "50px" }}
          >
            테스트계정 로그인
          </Button>
        </div>
      </div>
    );
  }
}

export default login;
